import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../layouts/Layout"
import Hero from "../components/Hero"
import Quotes from "../components/Quotes"
// import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"
import Courses from "../components/Courses"
import About from "../components/About"

const AboutPage = ({ data }) => {
  const image = getImage(data.author.image)
  return (
    <Layout>
      <SiteMetadata title="About" description="Sample description" />
      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
              <div className="space-y-6">
                <Hero data={data.author} />
                <About author={data.author} />
                <Courses courses={data.courses} />
              </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12 space-y-6">
              <GatsbyImage
                className="rounded-md shadow-md"
                image={image}
                alt="Kranthi Lakum"
              />
              <Quotes data={data.author.quotes} />
            </div>
          </div>
        </div>
      </div>
      {/* <Newsletter /> */}
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    author: contentfulPerson {
      name
      email
      quotes: childrenContentfulPersonQuotesJsonNode {
        id
        quote
      }
      image {
        gatsbyImageData(
          formats: [PNG, AUTO, WEBP]
          placeholder: BLURRED
          width: 600
          height: 480
          quality: 85
        )
      }
    }
    courses: allContentfulCourse {
      nodes {
        title
        summary
        status
        link
        certificate
      }
    }
  }
`
