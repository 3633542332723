import React from 'react';

const About = ({ author }) => {
    console.log(author);
    const experience = new Date().getFullYear() - new Date(2013, 11, 21).getFullYear();
    return (
        <section>
            <div className="mt-2 leading-loose">
                <p>
                    I am a software developer based in Hyderabad, India.
                    With over {" "}{experience} years of experience in developing software and web applications,
                    I have gained a deep understanding of various programming languages and frameworks.
                </p>
                <p>
                    I am proud to have worked for 7 years in the Telecommunications domain,
                    where I have gained valuable insights into the industry and developed software solutions that
                    have helped my clients achieve their goals.
                </p>
                <p>
                    I am proficient in working with JavaScript, TypeScript, Angular, React, Vue.js, Node.js, Java, Scala, and Python.
                    I am always up for new challenges and opportunities to learn and grow in my field.
                    I am committed to providing high-quality work to my clients,
                    and I believe in the power of collaboration and communication to achieve success.
                </p>
                    If you have any questions or are interested in working with me,
                    please don't hesitate to reach out to me at my <a href={"mailto:" + author.email}
                    className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
                >
                    E-mail
                </a>{" "} or <a
                    className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
                    href="https://www.linkedin.com/in/kranthilakum/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    LinkedIn
                </a>.
                I am always happy to hear from you!
                <br />
            </div>
        </section>
    );
}

export default About;
