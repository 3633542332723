import React, { useState } from 'react';

function Courses({courses}) {
    const [activeTab, setActiveTab] = useState(true);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="w-full max-w-3xl mx-auto border border-gray-300 rounded shadow">
            <div className="flex">
                <button
                    className={`${activeTab === true ? 'bg-emerald-500 text-white' : 'text-emerald-500 hover:bg-emerald-100'
                        } py-2 px-4`}
                    onClick={() => handleTabClick(true)}
                    disabled={activeTab === true}
                >
                    Finished Courses
                </button>
                <button
                    className={`${activeTab === false ? 'bg-emerald-500 text-white' : 'text-emerald-500 hover:bg-emerald-100'
                        } py-2 px-4`}
                    onClick={() => handleTabClick(false)}
                    disabled={activeTab === false}
                >
                    Ongoing Courses
                </button>
            </div>
            <div className="bg-white border-t border-gray-300">
                {activeTab === true ? (
                    <ul className="divide-y">
                    {courses.nodes
                        .filter((course) => course.status === true)
                        .map((course) => (
                            <li key={course.title} className="p-4 border-b border-gray-300">
                                <a href={course.link} target="_blank" rel="noopener noreferrer" className="text-gray-700 font-bold hover:underline">
                                    {course.title}
                                </a>
                                <a href={course.certificate} target="_blank" rel="noopener noreferrer" className="ml-auto ml-2">
                                    <i class="bi bi-patch-check-fill h-6 w-6 text-green-500"></i>
                                </a>
                                <p className="text-gray-600">{course.summary}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className="divide-y">
                        {courses.nodes
                            .filter((course) => course.status === false)
                            .map((course) => (
                                <li key={course.title} className="p-4 border-b border-gray-300">
                                    <a href={course.link} target="_blank" rel="noopener noreferrer" className="text-gray-700 font-bold hover:underline">
                                        {course.title}
                                    </a>
                                    <p className="text-gray-600">{course.summary}</p>
                                </li>
                            ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Courses;
