import React from 'react'

const Quotes = ({ data }) => {
  return (
    <div>
      {data && data.map(item =>
        <div key={item.id} className="bg-white border-l-4 border-emerald-500 text-gray-700 p-2 mb-2" role="alert">
          {item.quote}
        </div>
      )}
    </div>
  )
}

export default Quotes
